import React from 'react';

const Tutorials = () => {
return <>
    <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
        <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>TUTORIALS</h1>  </div>
           <div style={{color: "#d09e07", textAlign:"Left", padding: "30px", marginLeft: "auto", marginRight: "auto"}} >
                1. <a className="link" href="https://www.geeksforgeeks.org/how-to-install-and-use-metamask-on-google-chrome/" target="_blank" rel="noreferrer">How to Install Metamask Wallet on Google Chrome</a><br/>
                2. <a className="link" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank" rel="noreferrer">How to Add Binance Smart Chain in Metamask (Mainnet) </a><br/>
                3. <a className="link" href="https://crypto-explained.com/services/send-bnb-coin-to-binance-smart-chain-on-metamask/" target="_blank" rel="noreferrer">Transfer BNBs from Binance to Metamask Wallet </a><br />    
        </div>
    </div>
</>;
};

export default Tutorials;
