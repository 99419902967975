import React from "react";
import { useState } from "react";
import tokenLogo from "../images/pbtokenlogo.png";
import { Link } from "react-router-dom";
import { Navbar,Nav,Form, Button, Container } from "react-bootstrap";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core"
import { useEffect } from "react"
import TokenABI from './wallet/ABIPrintBitToken.json';


const TokenNavbar = ({ onButtonClick }) => {

    const [isConnected, setIsConnected] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [wallet, setwallet] = useState(null);
    const [bnb, setbnb] = useState(0);
    const [printbit, setprintbit] = useState(0);
    

    const contractAddress = global.config.Token.Contract.PrintBit; //Token Contract Address
   
    useEffect(() => {
        onButtonClick(wallet, bnb, printbit);
     }, [wallet, bnb, printbit])

    const onLogin = async () => {
        const provider = detectProvider();
        if (provider) {
            if (provider !== window.ethereum) {
                console.error ("No windows.ethereum provider found");
            }
        
        await provider.request({
            method: "eth_requestAccounts",
        });
        
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        const token = new web3.eth.Contract(TokenABI, contractAddress);

        if (accounts.length === 0) {
            setwallet(null);
            console.log("Please connect to metamask!")
        } else if (accounts[0] !== currentAccount){
            setCurrentAccount(accounts[0].substr(1, 4) + "...." + accounts[0].substr(accounts[0].length-4, accounts[0].length));
            setwallet(accounts[0]);
            var _bnbBal = await web3.eth.getBalance(accounts[0]);
            _bnbBal = web3.utils.fromWei(_bnbBal, "ether");
            //var _bnbBal = 100;
            setbnb(parseFloat(_bnbBal).toFixed(8));
            setIsConnected(true);
            // onButtonClick(wallet);
            var printbitBalance = await token.methods.balanceOf(accounts[0]).call();;
            printbitBalance = printbitBalance/10**18;
            //printbitBalance= web3.utils.fromWei(printbitBalance, "ether");
            printbitBalance = printbitBalance.toLocaleString(undefined, {maximumFractionDigits:2}) 
            setprintbit(printbitBalance);
            //setpb_price(0.123);
            console.log(accounts[0]);
            //web3.eth.getBalance(accounts[0]).then(console.log);
            
        }
        
      }

    }

    const onLogout = () => {
        setwallet(null);
        setbnb(0);
        setprintbit(0);
        setIsConnected(false);
        console.log("logged out...")
    }

    const detectProvider = () => {
        let provider;
        if (window.ethereum) {
            provider = window.ethereum;
        } else if (window.web3) {
            provider = window.web3.currentProvider;
        } else {
            window.alert("No metamask wallet installed!")
        }
        return provider;
    }
    const { active, account } = useWeb3React()



  return (
    <div className="row">
        <div className="col-md-12">
               <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
                   <Container>
               <Navbar.Brand as={Link} to="/"><img src={tokenLogo} width="100" height="100" className="d-inline-block align-top" alt="PrintBit Token Logo"/></Navbar.Brand>
               <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/trade">Trade</Nav.Link>
                    <Nav.Link as={Link} to="/chart">Chart</Nav.Link> 
                    <Nav.Link as={Link} to="/shop">Shop</Nav.Link>
                    <Nav.Link as={Link} to="/nft">NFT</Nav.Link>
                    <Nav.Link href="/printbit-coin-litepaper-v1.pdf" target='_blank'>Litepaper</Nav.Link>
                    <Nav.Link as={Link} to="/roadmap">Roadmap</Nav.Link>
                    <Nav.Link as={Link} to="/tutorials">Tutorials</Nav.Link>
                    {/* <Nav.Link href="/team">Team</Nav.Link> */}
                    {/* <Nav.Link as={Link} to="/contactus">Contact Us</Nav.Link> */}
                        {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown>*/}
                </Nav>
                <Form>
                    <Button variant="secondary" className="me-2" style={{textOverflow: "ellipsis"}} onClick={!isConnected ? onLogin : onLogout} >{!isConnected ? "Connect Wallet" : "Connected to " + currentAccount }</Button>
                    {/* <Button variant="secondary" className="me-2" style={{textOverflow: "ellipsis"}} onClick={onLogin}  >Connect Wallet</Button> */}
                    {active ? <span>Connected with <b>{account}</b></span> : <span>Not connected</span>}
                </Form>
                </Navbar.Collapse>
                </Container>
                </Navbar>
            </div>
        </div>
  )   
}

export default TokenNavbar;




