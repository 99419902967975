
import React, { useState, useEffect} from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import TokenNavbar from "./components/TokenNavbar"
import Home from "./components/Home"
import Footer from "./components/Footer"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Aboutus from './components/Aboutus';
import Terms from './components/Terms';
import SecurityPrivacy from './components/Security-Privacy';
import Team from './components/Team';
import Roadmap from './components/Roadmap';
import Trade from './components/Trade';
import Chart from './components/Chart';
import Shop from './components/Shop';
import NFT from './components/nft';
import Tutorials from './components/Tutorials';
import ContactUs from './components/ContactUs';
import NotFound from './components/NotFound';
import Qa from './components/Qa';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';

function getLibrary(provider) {
  return new Web3(provider)
}



function App() {
  const [walletAccount, setwalletAccount] = useState(null);
  const [bnbBalance, setbnbBalance] = useState(0);
  const [printbitBalance, setprintbitBalance] = useState(0);
  const [pbprice, setpbprice] = useState(0);

  const handleButtonClick = (wallet, bnb, printbit, pb_price) => {
    setwalletAccount(wallet);
    setbnbBalance(bnb);
    setprintbitBalance(printbit);
    setpbprice(pb_price);
  }  

//   useEffect(() => {
//     if(window.ethereum) {
//       window.ethereum.on('chainChanged', () => {
//         window.location.reload();
//       })
//       window.ethereum.on('accountsChanged', () => {
//         window.location.reload();
//       })
//   }
// }


  return (
     <>
       <Web3ReactProvider getLibrary={getLibrary}>
       <BrowserRouter>
       <TokenNavbar onButtonClick={handleButtonClick}/>
       <div style={{backgroundColor: "#001029"}}>      
       <Routes> 
         <Route  path='/' element={<Home/>} />       
         <Route  path='/trade' element={<Trade walletAcct={walletAccount} bnbBal={bnbBalance} printbitbal={printbitBalance} printbitprice={pbprice} />} />
         <Route  path='/chart' element={<Chart/>} />
         <Route  path='/shop' element={<Shop/>} />
         <Route  path='/nft' element={<NFT/>} />
         <Route  path='/roadmap' element={<Roadmap/>} />
         <Route  path='/tutorials' element={<Tutorials/>} />
         <Route  path='/contactus' element={<ContactUs/>} />
         <Route  path='/aboutus' element={<Aboutus/>} />
         <Route  path='/terms' element={<Terms/>} />
         <Route  path='/security' element={<SecurityPrivacy/>} />
         <Route  path='/team' element={<Team/>} />
         <Route  path='/qa' element={<Qa/>} />
         {/* <Route  path='/getData' element={<Data_getPrice/>} /> */}
         <Route path="*" element={<NotFound/>} />
       </Routes>
       
       </div>
       <Footer/>
       </BrowserRouter>
       </Web3ReactProvider>
     </>
  );
}

export default App;
