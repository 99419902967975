import React from 'react';
import iconContactUs from "../images/contactus.png";

const ContactUs = () => {
    return <>
    <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
        <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>CONTACT US</h1>  </div>
        <img src={iconContactUs} alt="Contact Us" width='400px'/>
    </div>
</>;
};

export default ContactUs;
