import { useEffect, useState } from "react";
import Web3 from "web3";
import TokenABI from './ABIDEX.json';



const GetTotalSupply = () => {
  const [tokensupply, settokensupply] = useState(0);

  useEffect(() => {
    TotalSupply();
  },)

  const TotalSupply = async () => {
    //   const provider = detectProvider();
    //   if (provider) {
    //      if (provider !== window.ethereum) {
    //          console.error ("No windows.ethereum provider found");
    //      }
    
    //  await provider.request({
    //      method: "eth_requestAccounts",
    //  });
    
    // const web3 = new Web3(provider);
    const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
    const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
    var _TotalSupply = await token.methods.tokenTotalSupply().call();;
    // setprintbit(printbitBalance/10**9);
    //settokensupply(parseFloat(web3.utils.fromWei(_TotalSupply, "ether")).toFixed(2));
    _TotalSupply = _TotalSupply/1e18;
    settokensupply(_TotalSupply.toLocaleString(undefined, {maximumFractionDigits:2}));
    // console.log(accounts[0]);
    //  }
  }

  //   const detectProvider = () => {
  //     let provider;
  //     if (window.ethereum) {
  //         provider = window.ethereum;
  //     } else if (window.web3) {
  //         provider = window.web3.currentProvider;
  //     } else {
  //       console.error ("No metamask wallet installed!")
  //     }
  //     return provider;
  // }
    

  return (    
        (tokensupply)
    
      )
}

export default GetTotalSupply