import React from 'react';
import iconNFT from "../images/nfts.jpg";
const nft = () => {
  return <>
      <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
          <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>NFTs</h1>  </div>
          <div  style={{color: "#d09e07", textAlign:"center", padding: "10px", marginLeft: "auto", marginRight: "auto", fontSize: "18px"}}>Sorry, this page is still under development. 
                Target launching is on September 18, 2022. Thanks!  <br /> <br/>
                <img src={iconNFT} alt="NFT" width='800px'/>
                </div>
      </div>
  </>;
};
export default nft;
