//import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import ParticlesPB from "./ParticlesPB";
import printbitcoin from "../images/printbitcoin.png"
import GetPricePBBNB from "./wallet/GetPricePBBNB";
import BinanceGetPriceUSDT from "./wallet/BinanceGetPriceUSDT";
import GetTotalSupply from "./wallet/GetTotalSupply";
import GetLockedTokens from "./wallet/GetLockedTokens";
import GetDEXTokens from "./wallet/GetDEXTokens";
import GetTransactions from "./wallet/GetTransactions";
import GetMarketCap from "./wallet/GetMarketCap";
import { Container, Row, Col } from 'react-bootstrap';


const Home = () => {

  return <>
    <Helmet>
        <title>{'PrintBit Coin'}</title>
    </Helmet>
    <div className="container" style={{backgroundColor: "#001029"}}>
      <div className="header-wrapper">
        <Container fluid>
          <Row>
              <Col><div> &nbsp;</div> </Col>
          </Row>
          <Row>
              <Col>
              {/* <div className="main-info"> */}
                <img src={printbitcoin} alt="PrintBit Coin"/>
                <p style={{color:"#A7A08E", fontSize: 12}}>PrintBit Coin is the official token of PrintBit Printing Shop on Binance Smart Chain &#40;BSC&#41;. It is verified, deflationary, being built on a robust platform and truly decentralized blockchain.
                          The token can be used to pay for all our products and services. Token contract address: 0xc535966dd473c2abb4aa8c0d4ce6d6343e5309b9
                </p>
              {/* </div> */}
              </Col>
              <Col> 
              <div className="pricebox " style={{color:"white", fontSize: 15, height: "25px", backgroundColor: "black", borderRadius: "8px"}}>
                CURRENT PRICE</div>
              <div className="pricebox"><BinanceGetPriceUSDT value={GetPricePBBNB()} tokensymbol="BNB"/> USDT</div>
                <div className="pricebox"><GetPricePBBNB/> BNB  </div> 
              </Col>
              <Col>
                <div className="announcementbox">
                  COIN UPDATES
                  <div style={{color:"rgb(248, 216, 71)", textAlign: "left", paddingLeft: "5px"}}>
                      Launching storefront soon ... <br/>
                      Products with NFTs next ... <br/>
                      Planning for pancakeswap listing ...
                  </div>
                </div>
              </Col>
          </Row>
          <Row>
            <Col>
              <div class="fade-in-text" style={{marginLeft: "5px"}}>
              Total Supply: <h4 style={{fontSize:"15px"}}><GetTotalSupply/> PrintBit Coins </h4>
              DEX Available Tokens: <h4 style={{fontSize:"15px"}}><GetDEXTokens/> </h4>
              Locked Tokens: <h4 style={{fontSize:"15px"}}><GetLockedTokens/> </h4>
              Market Cap: <h4 style={{fontSize:"15px"}}><GetMarketCap/> USDT </h4>          
              Transactions: <h4 style={{fontSize:"15px"}}><GetTransactions/> </h4>
          </div>
            </Col>
          </Row>
    </Container>
      
        
          <ParticlesPB/>
          
         
         

         </div>
  </div>
  </>;
}

export default Home;