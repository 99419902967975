import React from 'react';
import iconShop from "../images/shop.jpg";
const Shop = () => {
  return <>
      <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
          <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>SHOP</h1>  </div>
          <div  style={{color: "#d09e07", textAlign:"center", padding: "10px", marginLeft: "auto", marginRight: "auto", fontSize: "18px"}}>Sorry, this page is still under development. 
                Target launching is on June 18, 2022. Thanks! 
                <img src={iconShop} alt="Shop" width='800px'/>
                </div>
      </div>
  </>;
};
export default Shop;
