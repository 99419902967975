import { useEffect, useState } from "react";
import Web3 from "web3";
import TokenABI from './ABIPrintBitToken.json';



const GetLockedTokens = () => {
  const [lockedtokens, setlockedtokens] = useState(0);

  useEffect(() => {
    TotalLockedTokens();
  },)

  const TotalLockedTokens = async () => {
    //   const provider = detectProvider();
    //   if (provider) {
    //      if (provider !== window.ethereum) {
    //          console.error ("No windows.ethereum provider found");
    //      }
    
    //  await provider.request({
    //      method: "eth_requestAccounts",
    //  });
    
    // const web3 = new Web3(provider);
    const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
    const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.PrintBit);
    var _LockedTokens= await token.methods.getTotalLockedTokens().call();;
    // setprintbit(printbitBalance/10**9);
    //settokensupply(parseFloat(web3.utils.fromWei(_TotalSupply, "ether")).toFixed(2));
    _LockedTokens = _LockedTokens/1e18;
    setlockedtokens(_LockedTokens.toLocaleString(undefined, {maximumFractionDigits:2}));
    // console.log(accounts[0]);
    //  }
  }

  //   const detectProvider = () => {
  //     let provider;
  //     if (window.ethereum) {
  //         provider = window.ethereum;
  //     } else if (window.web3) {
  //         provider = window.web3.currentProvider;
  //     } else {
  //       console.error ("No metamask wallet installed!")
  //     }
  //     return provider;
  // }
    

  return (    
        (lockedtokens)
    
      )
}

export default GetLockedTokens