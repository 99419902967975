import { useEffect, useState } from "react";
import Web3 from "web3";
import TokenABI from './ABIDEX.json';



const GetPricePBBNB = () => {
  const [pbprice, setpbprice] = useState(0);
  

  useEffect(() => {
    getPrice();
  },)

//const contractAddress = "0xA9A79c2724B09A9754B9cb000c86476Dd2f302aF";  //DEX Contract
// const jsonAbi = [{
// 		"inputs": [],
// 		"name": "bnbPricePerToken",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
//   }];


  const getPrice = async () => {
    //   const provider = detectProvider();
    //   if (provider) {
    //      if (provider !== window.ethereum) {
    //          console.error ("No windows.ethereum provider found");
    //      }
    
    //  await provider.request({
    //      method: "eth_requestAccounts",
    //  });
    
    // const web3 = new Web3(provider);
    const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
    const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
    var printbitprice = await token.methods.bnbPricePerToken().call();;
    // setprintbit(printbitBalance/10**9);
    setpbprice(parseFloat(web3.utils.fromWei(printbitprice, "ether")).toFixed(12));
    // console.log(accounts[0]);
    //  }
  }

  //   const detectProvider = () => {
  //     let provider;
  //     if (window.ethereum) {
  //         provider = window.ethereum;
  //     } else if (window.web3) {
  //         provider = window.web3.currentProvider;
  //     } else {
  //       console.error ("No metamask wallet installed!")
  //     }
  //     return provider;
  // }
    

  return (    
        (pbprice)   
      )
}

export default GetPricePBBNB