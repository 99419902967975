module.exports = global.config = {
    Language: {
        welcome: {
            en: "Welcome",
            fa: "خوش آمدید"
        }
        // rest of your translation object
    },
    // other global config variables you wish
    Token: {
        Contract: {
            DEX:      "0x7ab0ff062e3d9459BA57937d864617E8F63f57C3",
            PrintBit: "0xc535966dD473C2aBb4AA8c0D4ce6D6343E5309b9"
        }
    }
};

// global.config.Token.Contract.DEX
//TESTNET
//DEX:      "0x12f28aE1C062d4dEb206831Bc39C59c8c4c8B714",
//PrintBit: "0x446c4FAfF5F742e89618a245491E52f0fb10Dae0"