import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import { useState, useEffect } from "react";
  import Web3 from 'web3';
  import TokenABI from './wallet/ABIDEX.json';
  import { Container, Row, Col } from 'react-bootstrap';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const Chart = () => {
 const [printbitprice, setprintbitprice] = useState();
 const [dataprice, setdataprice] = useState();
 const [DIVStyle, setDIVStyle] = useState('ChartCurrentPrice');
 const [labels, setlabels] = useState();
 const [lowprice, setlowprice] = useState(0);
 const [highprice, sethighprice] = useState(0);
 const [linecolor, setlinecolor] = useState('rgb(53, 162, 235)');
 const [runonce, setrunonce] = useState(false);
 var web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'))

 const getData = () => {
    const url = "https://coin.printbit.com/api/v1/price/date/24";
    
    //console.log((new Date()).toUTCString());
    fetch(url)
        .then((response) => response.json())
        .then((jsondata) => {
                              console.log(jsondata);
                              if (jsondata !== undefined && jsondata !==null)
                              { 
                                  var temp = JSON.stringify(jsondata);
                                  var obj = JSON.parse(temp);
                                  var values = Object.keys(obj).map(function (key) { return obj[key]; });
                                  var jsonArr = [];
                                  var labelCounter = 0;
                                  var labelCounter2 = 0;
                                  var labelArr = [];
                                  var datalength = obj.length;
                                  var datachunk = Math.floor(datalength / 8);
                                  var tempLow = 0;
                                  var tempHigh = 0;
                                  values.forEach(function(obj) { 
                                      jsonArr.push(parseFloat(web3.utils.fromWei(obj.usdtprice, "ether")).toFixed(12));
                                      labelCounter += 1;
                                      labelCounter2 += 1;
                                      var d = new Date(obj.dateTime);
                                      
                                      if (obj.usdtprice > tempHigh) {
                                        tempHigh = obj.usdtprice;
                                      }

                                      if (obj.usdtprice < tempLow) {
                                        tempLow = obj.usdtprice
                                      }

                                      if (labelCounter===1) {
                                        labelArr.push (d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }));
                                        tempLow = obj.usdtprice;
                                        tempHigh = obj.usdtprice;
                                      } else {
                                        if (labelCounter2 === datachunk) {
                                          labelArr.push (d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }));
                                            //console.log(labelCounter2);
                                            labelCounter2=0;
                                        } else { 
                                           //labelArr.push(""); //skip label
                                           labelArr.push(d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }));
                                        }
                                        if (labelCounter===datalength) {
                                          var tempprice = parseFloat(web3.utils.fromWei(obj.usdtprice, "ether")).toFixed(12);
                                          setprintbitprice(tempprice);

                                          //Change color RED for down, GREEN for UP
                                          if (tempprice < jsonArr[0]) {
                                            setDIVStyle('RED');
                                            setlinecolor('red');
                                          } else if (tempprice > jsonArr[0]){
                                            setDIVStyle('GREEN');
                                            setlinecolor('green');                                            
                                          } else {
                                            setDIVStyle('GREEN');
                                            setlinecolor('green'); 
                                          }
                                     //     console.log(tempprice);
                                     //     console.log(jsonArr[0]);
                                        }
                                      } 
                                   });
                                   //console.log(runonce);
                                   //console.log(labelArr);
                                   setdataprice(jsonArr);
                                   setlabels(labelArr);
                                   setlowprice(parseFloat(web3.utils.fromWei(tempLow, "ether")).toFixed(10));
                                   sethighprice(parseFloat(web3.utils.fromWei(tempHigh, "ether")).toFixed(10));
                             
                              }
                            }) //
        .catch((error) => console.log(error));
  }


  const data = {
    labels,
    datasets: [
      {
        label: 'PrintBit Coin to USDT',
        data: dataprice, 
        borderColor:  linecolor, //'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 1,
        borderWidth: 2,
      },
    ],
  };

  const customChartTooltip = (tooltipItems) => {
    let sum = 0;
  
    tooltipItems.forEach(function(tooltipItem) {
      sum = (tooltipItem.parsed.y).toFixed(12);
    });
    return 'Price: ' + sum + ' USDT';
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'PRICE IN THE LAST 24 HOURS',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return 'PrintBit Coin Price: '+ context.parsed.y.toFixed(12);
          },
          //footer: customChartTooltip,
        }
      }
    },
    scales: {                                   // newly added
        y: {
          display: true,
          title: {
            display: true,
            text: "Price in USDT"
          },
          ticks: {
            callback: function(value, index, ticks) {
              return value.toFixed(10);
            }
          },
        },
        x: {
          //type: 'number',
          text: 'Value',
          display: true,
          title: {
            display: false,
            text: "Time of the Day"
          },
          ticks: {
            stepSize: 1,
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
        }
        },
    },
  };

  useEffect(() => {   
    //setdataprice([ 0.0110, 0.106, 0.0107, 0.008, 0.4, 0.110, 0.0511, 0.010, 0.36, 0.45]);
  console.log(runonce);
  if (!runonce) {
    getData(); 
  } 

  const interval = setInterval(() => {
    getData(); 
  }, 10000);
  
  setrunonce(true);   
  },[]);   

  return <>
      <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
          <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>CHART</h1>  </div>

          <Container fluid>
              <Row>
                <Col>
                    <div className="ChartCurrentPrice" style={{color: "gray", fontSize: 13}}> Current Price </div>
                    <div className="ChartCurrentPrice"> <div className={DIVStyle}>{printbitprice} USDT</div> </div></Col>
                <Col>
                    <div className="ChartCurrentPrice" style={{color: "gray", fontSize: 13}}> PRINTBIT COIN Market Cap </div>
                    <div className="ChartCurrentPrice"> {(printbitprice * 88000000000).toLocaleString(undefined, { maximumFractionDigits: 2 })} USDT </div></Col>
                <Col>
                    <div className="ChartCurrentPrice" style={{color: "gray", fontSize: 13}}> Low / High </div>
                    <div className="ChartCurrentPrice" style={{fontSize: 12}} > {lowprice} / {highprice}</div></Col>
              </Row>
              <Row> 
                <Col>
                    <Line options={options} data={data} />    
                </Col>
              </Row>
          </Container>
          
          
      </div>
  </>;
};
export default Chart;
