import React from 'react';
import imgAboutUs from "../images/aboutus.png"

// console.log(imgAboutUs);

const Aboutus = () => {
  return <>
        <div className="bg-secondary">
        <div className="container">
            <div className="row h-100 align-items-center py-5">
            <div className="col-lg-6">
                <h4 className="display-6">About us </h4>
                <p className="lead mb-0">
                Founded in December 8 of 2008, PrintBit Printing Shop started as an Internet Cafe and expanded the business to cater large format printing services in 2011. 
                PrintBit is locally owned and now operates as a digital printing company based in Tagbilaran City, Bohol, Philippines. 
                As a printing shop, We strive for excellence to meet all your printing needs. 
                We are dedicated to cost-effective solutions and committed to providing the best services to our customers. 
                Our goal is to have the very best and most up-to-date printing technology available on the market, and to employ 
                the most experienced and innovative people in the industry. 
                We will measure our success, not by our sales figures, but by the satisfaction and longevity of our clients.             
                </p>
                <p className="lead mb-0">
                <br/>
                We started printing of documents in the internet cafe with just 1 Epson desktop printer. 
                In September of 2011, Roy E. Bayonas, founder / CEO, loaned a large format printer to cater the growing demand of tarpaulin banners. 
                The shop consisted of just 50 square meter space with only Roy and his wife, Nolie as employees. 
                Through hard work and determination, Roy was able to grow the business and establish a reputation for a digital printing shop 
                to produce quality print at affordable prices. The business grew through building relationships with customers and suppliers. 
                From printing of documents, Printbit now caters an array of printing services such as printing of tarpaulins, vinyl stickers, panaflex signages, 
                photopapers, t-shirts, mugs, pin buttons, acrylic plaques and also caters embroidery of logos for company uniforms. 
                </p>
                <p className="lead mb-0">
                    <br/>
                In 2017, we started our online shop. This is our way of expanding our customer-base to the whole of the Philippines.
                PrintBit continues to provide high quality printing services to local businesses, students and organizations in Bohol. 
                Currently located at Taloto District, Tagbilaran City, Bohol, Philippines, that houses advanced technology and equipment to provide full service printing for Tagbilaran City Area. With the leadership of Roy, PrintBit will continue to focus on providing quality services at affordable prices to our customers. We are always on the move and ahead as technology and demand changes.               
                <br/>
                <br/>
                In 2022, cryptocurrency is becoming popular. The Dev team decided to launch a printbit coin. The coin will be used to pay for the products and services
                offered by printbit printing shop. 
                </p>



                {/* <p className="lead text-muted">Snippet by <a href="https://bootstrapious.com/snippets" className="text-muted"> 
                            <u>Bootstrapious</u></a> */}
                {/* </p> */}
            </div>
            <div className="col-lg-6 d-none d-lg-block" ><img src={imgAboutUs} alt="About Us" className="img-fluid" style={{width: "300px"}}/> </div> 
            </div>
        </div>
        </div>
  </>;
};

export default Aboutus;
