import React from 'react';

const NotFound = () => {
  return <>
      <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
          <div  style={{color: "RED", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>PAGE NOT FOUND! </h1>  </div>
      </div>
  </>;
};

export default NotFound;
