import React from 'react';
import { useState, useEffect } from "react";
import iconPrice from "../images/icon-price.png";
import iconBNB from "../images/icon-bnb.png";
import iconPrintBit from "../images/icon-printbit.png";
import {Form, Button, Col, Row } from "react-bootstrap";
import BinanceGetPriceUSDT from './wallet/BinanceGetPriceUSDT';
import GetPricePBBNB from './wallet/GetPricePBBNB';
import Web3 from 'web3';
import TokenABI from './wallet/ABIDEX.json';
import PrintBitABI from './wallet/ABIPrintBitToken.json';


const Trade = ({ walletAcct, bnbBal, printbitbal }) => {
   const [currentWallet, setCurrentWallet] = useState(null);
   const [bnb, setbnb] = useState(0);
   const [printbit, setprintbit] = useState(0);
   const [buyAmount, setbuyAmount] = useState();
   const [minreceive, setminreceive] = useState(0);
   const [estimatereceive, setestimatereceive] = useState(0);
   const [sell_minreceive, setsell_minreceive] = useState(0);
   const [sell_estimatereceive, setsell_estimatereceive] = useState(0);
   const [printbitprice, setprintbitprice] = useState(0);
   const [sellAmount, setsellAmount] = useState();
   const [sellapprove, setsellapprove] = useState(false);
   const [processingBuy, setprocessingBuy] = useState(false);
   const [processingSell, setprocessingSell] = useState(false);

   function handleInputBUYChange(event) {
     setminreceive((event.target.value/printbitprice)*0.84);
     setestimatereceive((event.target.value/printbitprice)*0.98);
   }

   function handleInputSELLChange(event) {
    //getPrice();
    var pricePBtoBNB = 1/printbitprice;
    var _sellAmount = event.target.value; 
    _sellAmount = _sellAmount.replace(/,/g, '');
    setsell_minreceive((_sellAmount/pricePBtoBNB)*0.84);
    setsell_estimatereceive((_sellAmount/pricePBtoBNB)*0.97);
    
    setsellAmount(_sellAmount);
  }

  function handleCheckbox(e){
   // e.preventDefault();
    approveAllowance();
    //setsellapprove(!sellapprove);
  } 


   function handleBUYMaxButton(e) {
    e.preventDefault();
    if (!currentWallet) {
        alert("Connect to metamask wallet first!");
        return;
    }
    setbuyAmount(bnb);
    setminreceive((buyAmount/printbitprice)*0.84);
    setestimatereceive((buyAmount/printbitprice)*0.98);
  }

   useEffect(() => {
      
      setCurrentWallet(walletAcct);
      setbnb(bnbBal);
      setprintbit(printbitbal);
      getPrice();
      const provider = detectProvider();
      if (provider) {
         if (provider !== window.ethereum) {
             console.error ("No windows.ethereum provider found");
         }
         
         // reload when chain is changed
         window.ethereum.on('chainChanged', () => {
            window.location.reload();
         })
         // reload when account is changed
         window.ethereum.on('accountsChanged', () => {
            window.location.reload();
         })

        provider.request({
            method: "eth_requestAccounts",
        });
    
        const web3 = new Web3(provider);
        const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
        
        token.once('Bought', {
            filter: {}, // Using an array means OR: e.g. 20 or 23
            fromBlock: 0
       // }, alert("test"){ console.log(event); });
        }, function(error, event){ 
            //console.log(event); 
            getPrice();
           // alert("Emitted");
        });

        token.once('Sold', {
            filter: {}, // Using an array means OR: e.g. 20 or 23
            fromBlock: 0
       // }, alert("test"){ console.log(event); });
        }, function(error, event){ 
            //console.log(event); 
            getPrice();
           // alert("Emitted");
        });
        
      }
    }, [buyAmount, sellAmount, bnbBal]);     
   //}, [walletAcct, bnbBal, printbitbal, buyAmount, printbitprice, estimatereceive])

   const approveAllowance = async (e) => {
        if (!walletAcct) {
            alert("Connect to metamask wallet first!");
            return;
        }
        const provider = detectProvider();
        if (provider) {
            if (provider !== window.ethereum) {
                console.error ("No windows.ethereum provider found");
                return;
            }
    
            await provider.request({
                method: "eth_requestAccounts",
            });
    
            const web3 = new Web3(provider);
            const token = new web3.eth.Contract(PrintBitABI, global.config.Token.Contract.PrintBit);
            var amount = "10000000000";
            
            await token.methods.approve(global.config.Token.Contract.DEX, web3.utils.toWei(amount, 'ether')).send({
                from: walletAcct
            });
            setsellapprove(true);
        }
   }

   const checkAllowance = async (e) => {
    if (!walletAcct) {
        console.log("Connect to metamask wallet first!");
        return;
    }
    const provider = detectProvider();
    if (provider) {
        if (provider !== window.ethereum) {
            console.error ("No windows.ethereum provider found");
            return;
        }

        await provider.request({
            method: "eth_requestAccounts",
        });

        const web3 = new Web3(provider);
        const token = new web3.eth.Contract(PrintBitABI, global.config.Token.Contract.PrintBit);
        
        var allowance = await token.methods.allowance(walletAcct,  global.config.Token.Contract.DEX).send({
            from: walletAcct
        });
        if (allowance > 0) {
            setsellapprove(true);
        } else {
            setsellapprove(false);
        }
        
    }
}

   const buyPrintbit = async (e) => {
        e.preventDefault();
        setprocessingBuy(true);
        if (!walletAcct) {
            alert("Connect to metamask wallet first!");
            setprocessingBuy(false);
            return;
        }

        if (isNaN(buyAmount) || !buyAmount || buyAmount==0) {
            alert("Please input some BNBs!");
            setprocessingBuy(false);
            return;
        }
        
       const provider = detectProvider();
        if (provider) {
            if (provider !== window.ethereum) {
                console.error ("No windows.ethereum provider found");
            }
    
        await provider.request({
            method: "eth_requestAccounts",
        });
    
        const web3 = new Web3(provider);
        const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
        var amount = buyAmount;
        
        await token.methods.buy().send({
            from: walletAcct,
            value: web3.utils.toWei(amount, 'ether')
        }).on('error', function(error, receipt) { 
            //console.log(error);
            setprocessingBuy(false);
        }); 

        setbuyAmount('');
        setminreceive(0);
        setestimatereceive(0);
        }

        setprocessingBuy(false);
   }


   const sellPrintbit = async (e) => {
        e.preventDefault();
        setprocessingSell(true);
        if (!walletAcct) {
            alert("Connect to metamask wallet first!");
            setprocessingSell(false);
            return;
        }
        if (isNaN(sellAmount) || !sellAmount || sellAmount==0) {
            alert("Please input some PrintBit Coins!");
            setprocessingSell(false);
            return;
        }

        if (!sellapprove) {
            alert("Please click approve SELL switch");
            setprocessingSell(false);
            return;
        }
        //console.log(sellAmount);
        const provider = detectProvider();
        if (provider) {
            if (provider !== window.ethereum) {
                console.error ("No windows.ethereum provider found");
            }

            await provider.request({
                method: "eth_requestAccounts",
            });
        }

        const web3 = new Web3(provider);
        const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
        var amount = sellAmount;

        await token.methods.sell(web3.utils.toWei(amount, 'ether')).send({
            from: walletAcct
        }).on('error', function(error, receipt) { 
            //console.log(error);
            setprocessingSell(false);
        }); 

        setsellAmount('');
        setsell_minreceive(0);
        setsell_estimatereceive(0);
        setprocessingSell(false);
   }

   const getPrice = async () => {
   const provider = detectProvider();
    if (provider) {
       if (provider !== window.ethereum) {
           console.error ("No windows.ethereum provider found");
           return;
       }
       await provider.request({method: "eth_requestAccounts",});
  
    const web3 = new Web3(provider);
    const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
    var _printbitprice = await token.methods.bnbPricePerToken().call();
    //var _tokenBalance  = await token.methods.tokenBalance().call();
    // setprintbit(printbitBalance/10**9);
    setprintbitprice(parseFloat(web3.utils.fromWei(_printbitprice, "ether")).toFixed(12));
    //settokenBalance(web3.utils.fromWei(_tokenBalance, "ether"));
    //console.log(tokenBalance);
  
    if (walletAcct) {
        const accounts = await web3.eth.getAccounts();
        setCurrentWallet(accounts[0]);
        var _bnbBal = await web3.eth.getBalance(accounts[0]);
        _bnbBal = web3.utils.fromWei(_bnbBal, "ether");
        setbnb(parseFloat(_bnbBal).toFixed(8));
        //setIsConnected(true);
        const web32 = new Web3(provider);
        const token2 = new web32.eth.Contract(PrintBitABI, global.config.Token.Contract.PrintBit);
        var printbitBalance = await token2.methods.balanceOf(accounts[0]).call();
        printbitBalance = printbitBalance/10**18;
        printbitBalance = printbitBalance.toLocaleString(undefined, {maximumFractionDigits:2}) 
        setprintbit(printbitBalance);
        //get allowance
        var allowance = await token2.methods.allowance(walletAcct,  global.config.Token.Contract.DEX).call();
        if (allowance > 0) {
            setsellapprove(true);
        } else {
            setsellapprove(false);
        }
        // console.log(accounts[0]);
    }
   }
}

   const detectProvider = () => {
        let provider;
        if (window.ethereum) {
            provider = window.ethereum;
        } else if (window.web3) {
            provider = window.web3.currentProvider;
        } else {
            console.error ("No metamask wallet installed!")
        }
            return provider;
    }


    return <>
        {/* <div className="col-12 col-sm-8 col-lg-6"> */}
            <div className="container" style={{textAlign:"center", padding: "10px", marginLeft: "auto important", marginRight: "auto important"}}>
                <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>TRADE</h1>  </div>
                <div className='headerTrade'>
                    <img src={iconPrice} alt="Price"/>
                    <h4>PRICE</h4>
                    <br />
                    {printbitprice} BNB / PrintBit
                    <br/>
                    <BinanceGetPriceUSDT value={GetPricePBBNB()} tokensymbol="BNB"/> USDT
                </div>
                <div className='headerTrade'>
                    <img src={iconBNB} alt="BNB Balance"/>
                    <h4>BNB BALANCE</h4>
                    <br />
                    {bnb} BNB                     
                    <br/>
                    <BinanceGetPriceUSDT value={bnb} tokensymbol="BNB"/> USDT 
                   
                </div>
                <div className='headerTrade'>
                    <img src={iconPrintBit} alt="PrintBit Balance"/>
                    <h4>PRINTBIT BALANCE</h4>
                    <br/>
                    {printbit} PrintBit
                    <br/>
                    <BinanceGetPriceUSDT value={printbit} tokensymbol="PrintBit" bnbPrice={GetPricePBBNB}/> USDT
                </div>

                <div className="roundedBoxTrade">
                  <h4>BUY</h4>
                  <div style={{width: "400px", padding: "20px"}}> 
                        <Form >
                          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                              <Col sm={3}> <Form.Label> Amount  </Form.Label> </Col>
                              <Col sm={6} style={{padding: "0px", textAlign: "right"}}>
                                  <Form.Control type="text" placeholder="in BNB" value={buyAmount} onInput={e => setbuyAmount(e.target.value)} 
                                    onChange={handleInputBUYChange} />    
                              </Col>
                              <Col sm={2} style={{padding: "0px", textAlign: "left"}}>
                                    <Button type="submit" style={{width: "50px", backgroundColor: "#d09e07", borderColor: "none"}} onClick={handleBUYMaxButton}>Max</Button> 
                              </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck" style={{fontSize: "13px", padding:"0", paddingBottom: "0", textAlign: "left"}}>
                               <Col sm={{offset: 2 }} > 
                                  <Form.Label > Slippage: 12% </Form.Label> <br/>
                               </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck" style={{fontSize: "13px", padding:"0", paddingBottom: "0", textAlign: "left"}}>
                               <Col sm={{offset: 2 }} >      
                                  <Form.Label > Estimate Received: {estimatereceive} PrintBit </Form.Label> <br/>
                                  <Form.Label > Minimum Received: {minreceive} PrintBit </Form.Label>
                               </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 5, offset: 2 }}>
                                  <Button type="submit" disabled={processingBuy} style={{width: "150px"}} onClick={buyPrintbit}>{!processingBuy ? "Buy" : "Processing..."}</Button>
                             </Col>
                          </Form.Group>
                        </Form>
                  </div>         
                </div> 


                <div className="roundedBoxTrade">
                  <h4>SELL</h4>
                  <div style={{width: "400px", padding: "20px"}}> 
                        <Form >
                          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                              <Form.Label column sm={2}> Amount  </Form.Label>
                              <Col sm={10}>
                                  <Form.Control type="text" placeholder="in PrintBit " value={sellAmount} onInput={e => setsellAmount(e.target.value)} 
                                    onChange={handleInputSELLChange} />
                              </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck" style={{fontSize: "13px", padding:"0", paddingBottom: "0", textAlign: "left"}}>
                               <Col sm={{offset: 2 }} >                              
                                  <Form.Label > Slippage: 12% </Form.Label>  <br/>
                               </Col>
                               <Col>
                                  <Form.Check  type="switch" id="custom-switch" label="Approve SELL" checked={sellapprove} onChange={handleCheckbox}/> 
                               </Col>
                           </Form.Group>
                           <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck1" style={{fontSize: "13px", padding:"0", paddingBottom: "0", textAlign: "left"}}>
                               <Col sm={{offset: 2 }} >     
                                  <Form.Label > Estimate Received: {sell_estimatereceive} BNB </Form.Label> <br/>
                                  <Form.Label > Minimum Received: {sell_minreceive} BNB </Form.Label>
                               </Col>
                           </Form.Group>
                               

                          <Form.Group as={Row} className="mb-3">
                              <Col sm={{ span: 5, offset: 2 }}>
                                  <Button type="submit" disabled={processingSell} style={{width: "150px", backgroundColor: "Red", color:"White", borderColor: "red"}}  onClick={sellPrintbit}>{!processingSell ? "Sell" : "Processing..."}</Button> 
                             </Col>
                          </Form.Group>
                        </Form>
                  </div>         
                </div> 


            </div>
        {/* </div> */}


  </>;
};

export default Trade;
