import { useEffect, useState } from "react";
import Web3 from "web3";
import TokenABI from './ABIDEX.json';



const GetDEXTokens = () => {
  const [dextokens, setdextokens] = useState(0);

  useEffect(() => {
    TotalDEXTokens();
  },)

  const TotalDEXTokens = async () => {
    //   const provider = detectProvider();
    //   if (provider) {
    //      if (provider !== window.ethereum) {
    //          console.error ("No windows.ethereum provider found");
    //      }
    
    //  await provider.request({
    //      method: "eth_requestAccounts",
    //  });
    
    // const web3 = new Web3(provider);
    const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
    const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
    var _DEXTokens= await token.methods.tokenBalance().call();;
    _DEXTokens = _DEXTokens/1e18;
    setdextokens(_DEXTokens.toLocaleString(undefined, {maximumFractionDigits:2}));
    // }
  }

  //   const detectProvider = () => {
  //     let provider;
  //     if (window.ethereum) {
  //         provider = window.ethereum;
  //     } else if (window.web3) {
  //         provider = window.web3.currentProvider;
  //     } else {
  //       console.error ("No metamask wallet installed!")
  //     }
  //     return provider;
  // }
    

  return (    
        (dextokens)
    
      )
}

export default GetDEXTokens