import React from "react";
import Particles from "react-tsparticles";

const ParticlesPB = () => {
  return <div>
       <Particles
      id="tsparticles" 
    //   init={particlesInit}
    //   loaded={particlesLoaded}
      options={{
        preset: "fireworks",
        // background: {
        //   color: {
        //     value: "#0d47a1",
        //   },
        //},
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            // onHover: {
            //   enable: true,
            //   mode: "repulse",
            // },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.1,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffb700",
          },
          links: {
            color: "#ffb700",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 2,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: true,
            speed: 5,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 30,
          },
          opacity: {
            value: 0.3,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
  </div>;
};

export default ParticlesPB;
