import React from 'react';
import { Link } from "react-router-dom";
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
  return (
      <div className="box">
          <div className="container">
              <div className="row">
                  {/*COLUMN 1*/}
                  <div className="col-md-3 col-sm-6">
                      <h4 className="footer-Title">COMPANY</h4>
                        <ul className='list-unstyled'>
                            <li><Link to="/aboutus" className="footer-link">About Us</Link></li>
                            <li><Link to="/terms" className="footer-link">Terms and Conditions</Link></li>
                            <li><Link to="/security" className="footer-link">Security and Privacy Policy</Link></li>
                            <li><Link to="/team" className="footer-link">The Team</Link></li>
                        </ul>
                  </div>
                  {/*COLUMN 2*/}
                  <div className="col-md-3 col-sm-6">

                      <h4 className="footer-Title">CUSTOMER SUPPORT</h4>
                        <ul className='list-unstyled'>
                            <li><a href="https://bscscan.com/token/0xc535966dd473c2abb4aa8c0d4ce6d6343e5309b9" className="footer-link" target="_blank" rel="noreferrer">Verified Contract Address</a></li>
                            <li><Link to="/qa" className="footer-link">Q and A</Link></li>
                            <li><a href="https://www.youtube.com/channel/UC3YVLTtLW7KyyhwXI3Sg9UA" className="footer-link" target="_blank" rel="noreferrer">Youtube How Tos</a></li>
                            <li><Link to="/tutorials" className="footer-link">Tutorials</Link></li>
                        </ul>
                  </div>
                  {/*COLUMN 3*/}
                  <div className="col-md-3 col-sm-6">

                      <h4 className="footer-Title">CONTACT US</h4>
                        <ul className='list-unstyled'>
                            <li><a href="mailto:info@printbit.com" className="footer-link">info@printbit.com</a></li>
                            <li><a href="mailto:sales@printbit.com" className="footer-link">sales@printbit.com</a></li>
                            <li><a href="https://www.facebook.com/printbit/" className="footer-link" target="_blank" rel="noreferrer">FB Messenger</a></li>
                        </ul>
                  </div>

                  {/*COLUMN 4*/}
                  <div className="col-md-3 col-sm-6">

                      <h4 className="footer-Title">SOCIAL MEDIA</h4>
                        <ul className='list-unstyled'>
                            {/* <li><a href="//www.facebook.com/printbit" className="footer-link">Facebook Page</a></li> */}
                            <li><SocialIcon url="https://facebook.com/printbit" className="footer-link-social" target="_blank" rel="noreferrer" /> &nbsp;<SocialIcon url="https://twitter.com/printbit" className="footer-link-social" target="_blank" rel="noreferrer" /> &nbsp;
                            <SocialIcon url="https://www.youtube.com/channel/UC3YVLTtLW7KyyhwXI3Sg9UA" className="footer-link-social" target="_blank" rel="noreferrer"/>  &nbsp;<SocialIcon url="https://t.me/+emR_2HdXdOU4YWM1" className="footer-link-social" target="_blank" rel="noreferrer"/>
                            </li>
                            {/* <li><a href="https://www.youtube.com/channel/UC3YVLTtLW7KyyhwXI3Sg9UA" className="footer-link">Youtube</a></li> */}
                            {/* <li><Link to="/aboutus" className="footer-link">Telegram</Link></li> */}
                        </ul>
                  </div>
                {/* Fottom Bottom */}
                <div className="footer-bottom">
                    <p className="text-xs-center" >
                        &copy; {new Date().getFullYear()} PrintBit Printing Shop. All Rights Reserved.
                    </p>
                </div>
              </div>
          </div>


      </div>
  )
};

export default Footer;
