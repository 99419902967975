import React from 'react';
import imgTerms from "../images/terms.png"

//onsole.log(imgAboutUs);

const Terms = () => {
  return <>
        <div className="bg-secondary">
        <div className="container">
            <div className="row h-100 align-items-center py-5">
            <div className="col-lg-6">
            <div className="col-lg-6 d-none d-lg-block" ><img src={imgTerms} alt="About Us" className="img-fluid" style={{width: "300px"}}/> </div> 
                <h4 className="display-6">Terms and Conditions </h4>
                <p className="lead mb-0">
                    Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions 
                    of use, which together with our privacy policy govern PrintBit’s relationship with you in relation to this website. 
                    If you disagree with any part of these terms and conditions, please do not use our website.         
                </p>
                <p className="lead mb-0">
                <br/>
                    The term ‘PrintBit’ or ‘us’ or ‘we’ refers to the owner of the website whose registered office is at 2nd Fl. Gillamac’s Bldg., CPG Ave., Tagbilaran City, Bohol, Philippines. 
                    The term ‘you’ refers to the user or viewer of our website.
                </p>
                <p className="lead mb-0">
                <br/>
                    The use of this website is subject to the following terms of use:
                <ul>
                    <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                    <li>This website uses cookies to monitor browsing preferences.</li>
                    <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials
                        found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such
                        inaccuracies or errors to the fullest extent permitted by law.
                    </li>
                    <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own 
                        responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                    <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. 
                        Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>    
                    <li>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                    <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense .</li>
                    <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). 
                        We have no responsibility for the content of the linked website(s).</li>
                    <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of Tagbilaran City, Bohol, Philippines.</li>
                </ul>
                </p>
                <p className="lead mb-0">
                <br/>
                    Disclaimers and Limitation of Liability 
                <br/>
                    THE CONTENT ON THE SERVICE IS STRICTLY FOR INFORMATIONAL PURPOSES. NOTHING ON OR IN THE SERVICE SHALL CONSTITUTE OR BE CONSTRUED AS AN OFFERING OF ANY CURRENCY OR ANY FINANCIAL INSTRUMENT OR AS INVESTMENT ADVICE OR INVESTMENT RECOMMENDATIONS (SUCH AS RECOMMENDATIONS AS TO WHETHER TO PURCHASE A CURRENCY OR INSTRUMENT) BY PRINTBIT OR A RECOMMENDATION AS TO AN INVESTMENT STRATEGY BY PRINTBIT. CONTENT ON THIS SERVICE SHOULD NOT BE CONSIDERED AS INFORMATION SUFFICIENT UPON WHICH TO BASE AN INVESTMENT STRATEGY. NO CONTENT ON THE SERVICE IS TAILORED TO THE SPECIFIC NEEDS OF ANY INDIVIDUAL, ENTITY OR GROUP OF INDIVIDUALS. PrintBit EXPRESSES NO OPINION AS TO THE FUTURE OR EXPECTED VALUE OF ANY CURRENCY, SECURITY OR OTHER INTEREST. PRINTBIT DOES NOT EXPLICITLY OR IMPLICITLY RECOMMEND OR SUGGEST ANY INVESTMENT STRATEGY OF ANY KIND. CONTENT ON THE SERVICE MAY NOT BE USED AS A BASIS FOR ANY FINANCIAL OR OTHER PRODUCT WITHOUT THE EXPRESS PRIOR WRITTEN CONSENT OF PRINTBIT.
                <br/> <br/>
                    The Content provided on the Service is submitted to PRINTBIT by unrelated third-party providers. PRINTBIT does not review all Content for accuracy, does not review Content for completeness or reliability, and does not warrant or guarantee the accuracy, completeness, reliability or any other aspect of any Content.
                <br/> <br/>
                    THE SERVICE AND THE CONTENT PUBLISHED WITHIN THE SERVICE MAY INCLUDE INACCURACIES OR ERRORS. PRINTBIT DOES NOT GUARANTEE THE ACCURACY, COMPLETENESS, TIMELINESS, SECURITY, AVAILABILITY OR INTEGRITY OF THE SERVICE OR ANY CONTENT, AND DISCLAIMS ALL LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES RELATING TO THE SERVICE OR THE CONTENT. PRINTBIT MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE SERVICE OR ANY CONTENT, OR THAT THE SERVICE OR CONTENT WILL BE UNINTERRUPTED OR OPERATE IN COMBINATION WITH ANY SOFTWARE, SERVICE, SYSTEM OR OTHER DATA OR INFORMATION. THE SERVICE AND ALL CONTENT ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. PRINTBIT DISCLAIMS ALL WARRANTIES AND CONDITIONS THAT THIS SERVICE, ITS SERVERS, OR ANY EMAIL SENT FROM PRINTBIT, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PRINTBIT HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SERVICE AND THE CONTENT, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT.
                <br/> <br/>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PRINTBIT ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF ANY CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
                <br/> <br/>
                    IN NO EVENT SHALL PRINTBIT (AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, AGENTS, AND AFFILIATES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OR DAMAGES FOR LOST PROFITS, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS TO OR USE OF THE SERVICE OR WITH THE DELAY OR INABILITY TO ACCESS, DISPLAY, OR USE THE SERVICE; ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SERVICES, PRODUCTS, AND SERVICES OBTAINED THROUGH THE SERVICE; OR OTHERWISE ARISING OUT OF THE ACCESS TO OR USE OF THE SERVICE WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, AND EVEN IF PRINTBIT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL PRINTBIT (AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, AGENTS, AND AFFILIATES) BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN EXCESS OF $100.00. THE LIMITATION OF LIABILITY REFLECTS THE ALLOCATION OF RISK BETWEEN THE PARTIES. THE LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                <br/> <br/>
                    Indemnification <br/>
                    Except to the extent prohibited under applicable law, you shall indemnify, defend and hold harmless PRINTBIT and its members, managers, directors, officers, employees, partners, consultants, contractors, service providers, agents, successors and assigns from and against any and all suits, actions, proceedings and claims by third parties (whether threatened or actual), and all losses, liabilities, damages, judgments, costs and expenses (including reasonable attorneys' fees) arising out of, relating to or in connection with: (i) your use (or misuse) of and access to the Service or Content; (ii) your violation of any of these Terms; (iii) your violation of any applicable law, rule or regulation; (d) any claim that any information provided by you to PRINTBIT in connection with the Website, including the Content, caused damage to, infringed upon, misappropriated or otherwise violated the rights of any third party, including infringement, misappropriation or other violation of third-party intellectual property rights, or violation of any right of privacy or publicity; and/or (iv) any dispute that you have with any third party relating to or in connection with the Service or Content. PRINTBIT reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with PRINTBIT in asserting any available defenses and in the conduct of such defense.
                <br/> <br/>
                    Third Party Websites <br/>
                    The Service may contain links to third-party websites. Your use of all links to third-party websites is at your own risk. We do not monitor or have any control over, and make no claim or representation regarding third-party websites. To the extent such links are provided by us, they are provided only as a convenience, and a link to a third-party websites does not imply our endorsement, adoption or sponsorship of, or affiliation with, such third-party websites.
                <br/> <br/>
                    When you leave the Website, whether via a link contained on the Website or through the use of your web browser or other navigational tool, the information you view is not provided by us. Our terms and policies do not govern your use of third-party websites. We are not responsible for, have no control over and do not monitor or review the content of any other website. A link to a third-party website does not imply sponsorship, approval, affiliation or endorsement by PRINTBIT of the linked third-party website or of that third party's products or services.
                <br/> <br/>
                    YOU AGREE THAT PRINTBIT WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY WEBSITE OR SERVICES, FOR ANY DEALINGS OR COMMUNICATIONS YOU MAY HAVE WITH THIRD PARTIES, OR FOR ANY HARM, DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH ANY OF THE FOREGOING OR YOUR USE OF OR RELIANCE ON THE MATERIALS OR THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD PARTY.
                <br/> <br/>
                    Modification of the Website <br/>
                    At any time and in PRINTBIT's sole discretion, PRINTBIT may (in whole or in part) modify, suspend or discontinue the Service and/or any Content without notice, for any reason. We shall have no liability to you or to any third party for any such modification, suspension or discontinuance.
                <br/> <br/>
                    Changes to these Terms <br/>
                    We may revise these Terms from time to time, without prior notice. You are bound by any changes to these Terms upon our posting of such changes on the Service. You should check these Terms often to make certain that you are aware of the most current Terms.
                </p>


                {/* <p className="lead text-muted">Snippet by <a href="https://bootstrapious.com/snippets" className="text-muted"> 
                            <u>Bootstrapious</u></a> */}
                {/* </p> */}
            </div>
            
            </div>
        </div>
        </div>
  </>;
};

export default Terms;
