import React from 'react';
import { Helmet } from 'react-helmet';
import Yorbs from "../images/yorbs.jpg"
import Nolie from "../images/nolie.jpg"
import Badak from "../images/badak.jpg"
import Ian from "../images/ian.jpg"
import Felcris from "../images/felcris.jpg"
import Renan from "../images/renan.jpg"
import Veen from "../images/veen.jpg"

const Team = () => {
  return <>
    <Helmet>
        <title>{'The Team'}</title>
    </Helmet>
    <div className='container' style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}} >
        <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>The Team</h1>  </div>
            <div className="roundedBox">
                <img src={Yorbs} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Roy Bayonas</b> <br/>
                Founder / Fullstack Developer<br/>
                Former System Engineer @ P&G<br/>
                Former Tech. Consultant @ HP<br/>
                BISU - BSIT
            </div>
            <br/>
            <div className="roundedBox">
                <img src={Nolie} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Nolie Christy Bayonas</b> <br/>
                   Developer<br/>
                Former System Support<br/>
                HNU-BS Computer Science
            </div>
            <div className="roundedBox">
                <img src={Badak} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Rhea Reputana</b> <br/>
                Sys Admin<br/>
                BISU - BSIT <br/><br/>
            </div>
            <br/>
            <div className="roundedBox">
                <img src={Ian} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Ian Opena</b> <br/>
                UI Dev / Designer <br/>
                UB - Fine Arts  <br/><br/>
            </div>
            
            <div className="roundedBox">
                <img src={Felcris} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Felcris Gementiza</b> <br/>
                UI Dev / Designer<br/>
                STI - Com Sci<br/> <br/>
                
            </div>
            <div className="roundedBox">
                <img src={Renan} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Renan Gula</b> <br/>
                Graphic Designer<br/>
               <br/> <br/>
            </div>
            <div className="roundedBox">
                <img src={Veen} alt="About Us" className="img-fluid" style={{width: "150px", borderRadius: "50%", display: "block", marginLeft: "auto", marginRight: "auto"}}/>
                <b>Veen Shane Ostos</b> <br/>
                Media Relations<br/>
                <br/>
            </div>
        </div>
  </>;
};

export default Team;
