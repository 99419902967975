import React from 'react'
import { useEffect } from "react"
import Web3 from "web3";
import TokenABI from './ABIDEX.json';



const BinanceGetPriceUSDT = ({ value, tokensymbol }) => {
    const [data, setData] = React.useState([]);
    const [bnbval, setbnbval] = React.useState(0);
    const [symbol, setsymbol] = React.useState();
    const [tokenprice, settokenprice] = React.useState(0);

    useEffect(() => {
        value = parseFloat(value.toString().replace(/[,]/g,''));
        setbnbval(value);
        setsymbol(tokensymbol);
        getData();
        getPrice();
     }, [value, tokenprice])


    const getData = () => {
        const url = "https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT";
        fetch(url)
            .then((response) => response.json())
        .then((json) => setData(json['price']))
        .catch((error) => console.log(error));  
    }

    const getPrice = async () => {
    //   const provider = detectProvider();
    //   if (provider) {
    //      if (provider !== window.ethereum) {
    //          console.error ("No windows.ethereum provider found");
    //      }
    
    //  await provider.request({
    //      method: "eth_requestAccounts",
    //  });
    
    // const web3 = new Web3(provider);
    const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
    const token = new web3.eth.Contract(TokenABI, global.config.Token.Contract.DEX);
    var printbitprice = await token.methods.bnbPricePerToken().call();;
    // setprintbit(printbitBalance/10**9);
    settokenprice(parseFloat(web3.utils.fromWei(printbitprice, "ether")).toFixed(12));
    // console.log(accounts[0]);
    //  }
  }

  //   const detectProvider = () => {
  //     let provider;
  //     if (window.ethereum) {
  //         provider = window.ethereum;
  //     } else if (window.web3) {
  //         provider = window.web3.currentProvider;
  //     } else {
  //       console.error ("No metamask wallet installed!")
  //     }
  //     return provider;
  // }
    
  return ( 
    symbol==="BNB"? (parseFloat(bnbval) * parseFloat(data)).toFixed(10) : (parseFloat(bnbval*tokenprice) * parseFloat(data)).toFixed(10)
  )
}

export default BinanceGetPriceUSDT