import React from 'react';
import { Accordion } from "react-bootstrap";
const Qa = () => {
return <>
    <div className="container" style={{textAlign:"center", padding: "30px", marginLeft: "auto important", marginRight: "auto important"}}>
        <div  style={{color: "#d09e07", textAlign:"center", padding: "30px", marginLeft: "auto", marginRight: "auto"}} ><h1>Q & A</h1>  </div>
           <div className="container" >
           <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0" >
                    <Accordion.Header className="accordion-header">
                        1. What is the purpose of PrintBit Token?
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body">
                        The main purpose of the token is to pay for all our products, services and future related businesses.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    2. How much is the minimum to trade?
                    </Accordion.Header>
                    <Accordion.Body>
                    There is no minimum but there is a maximum amount of 5% of the available supply per transaction. This is to prevent pump and dump.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    3. What cryptocurrency to use in trading?
                    </Accordion.Header>
                    <Accordion.Body>
                        Since we are using binance smart chain, you need to fund your wallet with BNBs.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    4. Can I trade using fiat money? 
                    </Accordion.Header>
                    <Accordion.Body>
                        As of the moment, we can't convert your fiat money to printbit token.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    5. How to trade? 
                    </Accordion.Header>
                    <Accordion.Body>
                        Trading is only available at our website. Just click the TRADE button at the main menu above.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    6. What wallet is supported?
                    </Accordion.Header>
                    <Accordion.Body>
                        We only support metamask wallet for now but will support more wallets in the future.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    7. When was the token launched?
                    </Accordion.Header>
                    <Accordion.Body>
                        March 18, 2022
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>
                    8. Is it safe to buy this token?
                    </Accordion.Header>
                    <Accordion.Body>
                       PrintBit is in business for almost 14 years, since 2008. Printbit token was launched on March 18, 2022 to cater the growing crypto communities. 
                       We guarantee that this is not a rugpull token. The developers have no way to drain the contract. There are no remove liquidity and mint functions. 
                       The developers are not hiding their names. You can check at the TEAM section of this site.  
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>
                    9. Link of the smart contract
                    </Accordion.Header>
                    <Accordion.Body>
                        <a href="https://bscscan.com/token/0xc535966dd473c2abb4aa8c0d4ce6d6343e5309b9" target="_blank" rel="noreferrer">Token Contract</a>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>
                    10. I have some other questions, where to contact?
                    </Accordion.Header>
                    <Accordion.Body>
                        You can drop us an email at info@printbit.com or message us at printbit facebook messenger.  
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </div>
    </div>
</>;
};

export default Qa;
