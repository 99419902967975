import React from 'react';

const Roadmap = () => {
  return <>
    <section class="timeline_area section_padding_130">
        <div className="container" style={{padding: "30px"}}>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-lg-6">
                    {/* <!-- Section Heading--> */}
                        <div className="section_heading text-center" style={{padding: "20px"}}>
                            {/* <h6>Our History</h6> */}
                            <h1 style={{color: "#d09e07"}}>Our 2022 Roadmap</h1>
                            <div className="line"></div>
                        </div>
                </div>
            </div>

            <div className="row" style={{display: "block", marginLeft: "100px", marginRight: "auto"}}>
                <div className="col-12">
                    {/* <!-- Timeline Area--> */}
                    <div className="apland-timeline-area">
                        {/* <!-- Single Timeline Content--> */}
                        <div className="single-timeline-area">
                            <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "1s", animationName: "fadeInLeft"}}>
                                <p>March 18, 2022</p>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInLeft"}}>
                                        <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div>
                                        <div className="timeline-text">
                                            <h6>Token Launching</h6>
                                            <p>88 B PrintBit coins will be minted.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{display: "block", marginLeft: "100px", marginRight: "auto"}}>
                <div className="col-12">
                    {/* <!-- Timeline Area--> */}
                    <div className="apland-timeline-area">
                        {/* <!-- Single Timeline Content--> */}
                        <div className="single-timeline-area">
                            <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeft"}}>
                                <p>July 18, 2022</p>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInLeft"}}>
                                        <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div>
                                        <div className="timeline-text">
                                            <h6>Product Listing</h6>
                                            <p>T-shirts, mugs, hoddies and etc. can be purchased using PrintBit Coin</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{display: "block", marginLeft: "100px", marginRight: "auto"}}>
                <div className="col-12">
                    {/* <!-- Timeline Area--> */}
                    <div className="apland-timeline-area">
                        {/* <!-- Single Timeline Content--> */}
                        <div className="single-timeline-area">
                            <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeft"}}>
                                <p>September 18, 2022</p>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInLeft"}}>
                                        <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div>
                                        <div className="timeline-text">
                                            <h6>Products with NFTs</h6>
                                            <p>T-shirts, mugs, hoddies and etc. can be purchased using PrintBit Coin with NFTs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{display: "block", marginLeft: "100px", marginRight: "auto"}}>
                <div className="col-12">
                    {/* <!-- Timeline Area--> */}
                    <div className="apland-timeline-area">
                        {/* <!-- Single Timeline Content--> */}
                        <div className="single-timeline-area">
                            <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeft"}}>
                                <p>October 18, 2022</p>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInLeft"}}>
                                        <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div>
                                        <div className="timeline-text">
                                            <h6>Wallet App</h6>
                                            <p>PrintBit Coins can now be stored in our very own wallet application</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{display: "block", marginLeft: "100px", marginRight: "auto"}}>
                <div className="col-12">
                    {/* <!-- Timeline Area--> */}
                    <div className="apland-timeline-area">
                        {/* <!-- Single Timeline Content--> */}
                        <div className="single-timeline-area">
                            <div className="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: "visible", animationDelay: "0.1s", animationName: "fadeInLeft"}}>
                                <p>December 18, 2022</p>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeInLeft"}}>
                                        <div className="timeline-icon"><i className="fa fa-address-card" aria-hidden="true"></i></div>
                                        <div className="timeline-text">
                                            <h6>PrintBit Mobile App</h6>
                                            <p>Products and services can be bought using the app </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>;
};

export default Roadmap;
